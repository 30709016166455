@media (max-width: $screen-sm) {
    // sm-view
    .categories-list { 
        margin: 0 0 16px;
        .categories-list-holder { 
            padding: 0;
            width: calc(100% +  10px);
            margin-left: -5px;
            .category {
                width: 50%;
                padding: 0 5px;
                margin: 0 0 13px;
                .category-holder { 
                    .category-container { 
                        height: 65px;

                        .category-image { 
                            img {
                                max-height: 52px;
                                max-width: 64px;
                            }
                        }
                        .category-image.image-1 {
                            img {
                                max-height: 65px;
                                max-width: 64px;
                            }
                        }
                        .category-image.image-2 {
                            img {
                                max-height: 58px;
                                max-width: 64px;
                            }
                        }
                        .category-image.image-3 {
                            left: 12px;
                        }
                        .category-image.image-4 {
                            img {
                                max-height: 63px;
                                max-width: 64px;
                            }
                        }
                        .category-image.image-5 {
                            img {
                                max-height: 65px;
                                max-width: 79px;
                            }
                        }
                        .category-image.image-6 {
                            left: 10px;
                            top: 49%;
                            img {
                                max-height: 53px;
                                max-width: 52px;
                            }
                        }
                        .category-image.image-8 {
                            
                        }

                        .category-desc { 
                            padding: 4px 10px 0 86px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
