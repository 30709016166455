.catalog-detail-tabs { 
    display: inline-block;
    width: 100%;
    margin: 0 0 23px;
	.catalog-detail-tabs-holder { 
        display: inline-block;
        width: 100%;
        .tabs-selector-block { 
            display: inline-block;
            width: 100%;
            margin: 0 0 21px;
            border-top: 1px solid #CCCCCC;
            padding-top: 52px;
            .tabs-selector-block-holder { 
                display: inline-block;
                width: 100%;
                margin: 0 -25px;
                .tab { 
                    float: left;
                    margin: 0 25px;
                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28px;
                        color: $gray-base;
                        position: relative;
                        &:before {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 3px;
                            background: $brand-primary;
                            position: absolute;
                            left: 0;
                            top: -54px;
                            opacity: 0;
                            transition: all 300ms;
                        }
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                    a.current { 
                        color: $brand-primary;
                        pointer-events: none;
                        touch-action: none;
                        &:before {
                            opacity: 1;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }

        .tabs { 

            .tab { 

                .inner-tab { 
                    display: inline-block;
                    width: 100%;
                    .desc { 
                        display: inline-block;
                        width: 74%;
                        p { 

                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/detail_tabs";
@import "../../media/mobile/includes/catalog/detail_tabs";
