@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-detail { 
        margin: 31px 0 0;
        .catalog-detail-holder { 
            width: calc(100% + 31px);
            margin-left: -15.5px;
            box-sizing: border-box;
            padding: 0 1.5%;
            .image-gallery-block { 
                width: 49.5%;
                padding: 0 15.5px;
                .image-gallery-block-holder {
                    height: auto;
                    .image-gallery { 
                        height: 44.5vw;
                        margin: 0 0 16px;
                    }
                }
            }

            .items-desc { 
                width: 50.5%;
                padding: 0 15.5px;
                .item-desc-holder { 
                    .name {
                        min-height: 117px;
                        margin: 0 0 4px;
                        .name-holder {
                            width: 100%;
                            >* {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 30px;
                                line-height: 36px;
                            }
                        }
                    }

                    .pricing { 
                        margin: 0 0 56px;
                        .pricing-holder { 
                            .value {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 26px;
                                line-height: 31px;
                            }

                            .old-value { 
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 31px;
                                margin-left: 38px;
                            }
                        }
                    }

                    .variants { 
                        margin: 0 0 33px;
                        .variant { 
                            .variant-holder { 
                                .heading {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 21px;
                                }

                                .variant-selector { 
                                    margin: 0 -8px;
                                    .variant-type { 
                                        margin: 0 8px;
                                        a {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .action {
                        .btn {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            min-width: 201px;
                            padding: 12px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
