@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-detail-tabs { 
        margin: 0 0 31px;
        .catalog-detail-tabs-holder { 
            box-sizing: border-box;
            padding: 0 1.5%;
            .tabs-selector-block { 
                margin: 0 0 19px;
            }

            .tabs { 

                .tab { 
    
                    .inner-tab { 
                        .desc { 
                            width: 100%;
                            p { 
    
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
