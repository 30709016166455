@media (max-width: $screen-sm) {
    // sm-view
    .catalog-detail-tabs { 
        margin: 0 0 31px;
        .catalog-detail-tabs-holder { 
            box-sizing: border-box;
            padding: 0 2%;
            .tabs-selector-block { 
                margin: 0 0 19px;
                padding-top: 42px;
                .tabs-selector-block-holder { 
                    margin: 0 -15px;
                    .tab { 
                        margin: 0 15px;
                        a {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            &:before {
                                top: -44px;
                            }
                        }
                    }
                }
            }

            .tabs { 

                .tab { 
    
                    .inner-tab { 
                        .desc { 
                            width: 100%;
                            p { 
    
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
