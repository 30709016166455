.categories-list { 
    display: inline-block;
    width: 100%;
    margin: 0 0 26px;
	.categories-list-holder { 
        display: inline-block;
        width: calc(100% + 22px);
        margin-left: -11px;
        .category { 
            width: 25%;
            float: left;
            box-sizing: border-box;
            padding: 0 11px;
            margin: 0 0 22px;
            .category-holder { 
                display: inline-block;
                width: 100%;
                .category-container { 
                    display: inline-block;
                    width: 100%;
                    height: 108px;
                    position: relative;
                    background: $gray-lighter;
                    color: $gray-base;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    border-radius: 10px;
                    overflow: hidden;
                    &:hover {
                        background: $brand-primary;
                        color: white;
                    }
                    .category-image { 
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        img { 
                            max-height: 107px;
                            max-width: 114px;
                        }
                    }
                    .category-image.image-1 {
                        left: 19px;
                    }
                    .category-image.image-2 {
                        left: 26px;
                    }
                    .category-image.image-3 {
                        left: 10px;
                    }
                    .category-image.image-5 {
                        top: auto;
                        transform: none;
                        bottom: 0;
                        left: -14px;
                    }
                    .category-image.image-6 {
                        left: 17px;
                        top: 46%;
                    }
                    .category-image.image-8 {
                        top: auto;
                        transform: none;
                        bottom: 3px;
                    }

                    .category-desc { 
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 0 0 79px;
                        .name { 
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/categories_list";
@import "../../media/mobile/includes/catalog/categories_list";
