.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 21px;
  margin-bottom: 19px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  margin-right: 5px;
  color: #959C9F;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #9C2E3B;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #d0606d;
  transition: all 300ms;
}
.categories-list {
  display: inline-block;
  width: 100%;
  margin: 0 0 26px;
}
.categories-list .categories-list-holder {
  display: inline-block;
  width: calc(100% + 22px);
  margin-left: -11px;
}
.categories-list .categories-list-holder .category {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 0 11px;
  margin: 0 0 22px;
}
.categories-list .categories-list-holder .category .category-holder {
  display: inline-block;
  width: 100%;
}
.categories-list .categories-list-holder .category .category-holder .category-container {
  display: inline-block;
  width: 100%;
  height: 108px;
  position: relative;
  background: #F2F3F5;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 10px;
  overflow: hidden;
}
.categories-list .categories-list-holder .category .category-holder .category-container:hover {
  background: #9C2E3B;
  color: white;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image img {
  max-height: 107px;
  max-width: 114px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-1 {
  left: 19px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-2 {
  left: 26px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-3 {
  left: 10px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-5 {
  top: auto;
  transform: none;
  bottom: 0;
  left: -14px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-6 {
  left: 17px;
  top: 46%;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-8 {
  top: auto;
  transform: none;
  bottom: 3px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-desc {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 79px;
}
.categories-list .categories-list-holder .category .category-holder .category-container .category-desc .name {
  text-align: center;
  width: 100%;
}

@media (max-width: 1279px) {
  .categories-list .categories-list-holder {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .categories-list .categories-list-holder .category {
    padding: 0 7.5px;
    margin: 0 0 14px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container {
    height: 70px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image img {
    max-height: 67px;
    max-width: 81px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-1 {
    left: 13px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-2 {
    left: 13px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-3 {
    left: -1px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-6 {
    left: 7px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-6 img {
    max-height: 60px;
    max-width: 58px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-desc {
    padding: 0 10px 0 86px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-desc .name {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .categories-list {
    margin: 0 0 16px;
  }
  .categories-list .categories-list-holder {
    padding: 0;
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .categories-list .categories-list-holder .category {
    width: 50%;
    padding: 0 5px;
    margin: 0 0 13px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container {
    height: 65px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image img {
    max-height: 52px;
    max-width: 64px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-1 img {
    max-height: 65px;
    max-width: 64px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-2 img {
    max-height: 58px;
    max-width: 64px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-3 {
    left: 12px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-4 img {
    max-height: 63px;
    max-width: 64px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-5 img {
    max-height: 65px;
    max-width: 79px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-6 {
    left: 10px;
    top: 49%;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-image.image-6 img {
    max-height: 53px;
    max-width: 52px;
  }
  .categories-list .categories-list-holder .category .category-holder .category-container .category-desc {
    padding: 4px 10px 0 86px;
  }
}
.catalog-detail-tabs {
  display: inline-block;
  width: 100%;
  margin: 0 0 23px;
}
.catalog-detail-tabs .catalog-detail-tabs-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 21px;
  border-top: 1px solid #CCCCCC;
  padding-top: 52px;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder {
  display: inline-block;
  width: 100%;
  margin: 0 -25px;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab {
  float: left;
  margin: 0 25px;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  position: relative;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 3px;
  background: #9C2E3B;
  position: absolute;
  left: 0;
  top: -54px;
  opacity: 0;
  transition: all 300ms;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a:hover {
  color: #9C2E3B;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a.current {
  color: #9C2E3B;
  pointer-events: none;
  touch-action: none;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a.current:before {
  opacity: 1;
  transition: all 300ms;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs .tab .inner-tab {
  display: inline-block;
  width: 100%;
}
.catalog-detail-tabs .catalog-detail-tabs-holder .tabs .tab .inner-tab .desc {
  display: inline-block;
  width: 74%;
}
@media (max-width: 1279px) {
  .catalog-detail-tabs {
    margin: 0 0 31px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder {
    box-sizing: border-box;
    padding: 0 1.5%;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block {
    margin: 0 0 19px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs .tab .inner-tab .desc {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .catalog-detail-tabs {
    margin: 0 0 31px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder {
    box-sizing: border-box;
    padding: 0 2%;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block {
    margin: 0 0 19px;
    padding-top: 42px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder {
    margin: 0 -15px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab {
    margin: 0 15px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs-selector-block .tabs-selector-block-holder .tab a:before {
    top: -44px;
  }
  .catalog-detail-tabs .catalog-detail-tabs-holder .tabs .tab .inner-tab .desc {
    width: 100%;
  }
}
.catalog-detail {
  display: inline-block;
  width: 100%;
  margin: 39px 0 137px;
}
.catalog-detail .catalog-detail-holder {
  display: inline-block;
  width: calc(100% + 41px);
  margin-left: -20.5px;
}
.catalog-detail .catalog-detail-holder .image-gallery-block {
  float: left;
  width: 43.5%;
  box-sizing: border-box;
  padding: 0 20.5px;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder {
  display: inline-block;
  width: 100%;
  height: 25vw;
  margin: 3px 0 0;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .prev, .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .next {
  left: 12px;
  transform: translateY(-22%);
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .next {
  left: auto;
  right: 12px;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .image-gallery-holder {
  height: 100%;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .image-gallery-holder .image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .image-gallery-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .image-gallery-holder .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery .image-gallery-holder .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .image-preview-gallery-holder {
  width: calc(100% + 7px);
  margin-left: -3.5px;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .image-preview-gallery-holder .image {
  width: 100%;
  height: 10.5vw;
  box-sizing: border-box;
  padding: 0 3.5px;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .image-preview-gallery-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
}
.catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .image-preview-gallery-holder .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-detail .catalog-detail-holder .items-desc {
  float: left;
  width: 56.5%;
  box-sizing: border-box;
  padding: 0 20.5px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .name {
  display: inline-block;
  width: 100%;
  margin: 0 0 13px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .name .name-holder {
  display: inline-block;
  width: 79%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing {
  display: inline-block;
  width: 100%;
  margin: 0 0 47px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder {
  display: flex;
  align-items: center;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .value {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #9C2E3B;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .old-value {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #63636C;
  text-decoration: line-through;
  margin-left: 26px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants {
  display: inline-block;
  width: 100%;
  margin: 0 0 46px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .heading {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 24px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector {
  display: inline-block;
  width: 100%;
  margin: 0 -10.5px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector .variant-type {
  float: left;
  margin: 0 10.5px;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector .variant-type a {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 1px solid #000000;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector .variant-type a:hover {
  border-color: #9C2E3B;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .action {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .action .btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border-radius: 8px;
  min-width: 268px;
  text-align: center;
  padding: 18px 20px;
}

@media (max-width: 1279px) {
  .catalog-detail {
    margin: 31px 0 0;
  }
  .catalog-detail .catalog-detail-holder {
    width: calc(100% + 31px);
    margin-left: -15.5px;
    box-sizing: border-box;
    padding: 0 1.5%;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block {
    width: 49.5%;
    padding: 0 15.5px;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder {
    height: auto;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery {
    height: 44.5vw;
    margin: 0 0 16px;
  }
  .catalog-detail .catalog-detail-holder .items-desc {
    width: 50.5%;
    padding: 0 15.5px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .name {
    min-height: 117px;
    margin: 0 0 4px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .name .name-holder {
    width: 100%;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .name .name-holder > * {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing {
    margin: 0 0 56px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .value {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .old-value {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    margin-left: 38px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants {
    margin: 0 0 33px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector {
    margin: 0 -8px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector .variant-type {
    margin: 0 8px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants .variant .variant-holder .variant-selector .variant-type a {
    width: 40px;
    height: 40px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .action .btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    min-width: 201px;
    padding: 12px 10px;
  }
}
@media (max-width: 767px) {
  .catalog-detail {
    overflow: hidden;
    margin: 47px 0 30px;
  }
  .catalog-detail .catalog-detail-holder {
    width: 100%;
    margin-left: 0;
    padding: 0 1%;
  }
  .catalog-detail .catalog-detail-holder .name {
    width: 102%;
    margin: 0 0 28px;
  }
  .catalog-detail .catalog-detail-holder .name .name-holder {
    display: inline-block;
    width: 100%;
  }
  .catalog-detail .catalog-detail-holder .name .name-holder > * {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block {
    width: 100%;
    padding: 0;
    margin: 0 0 15px;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-gallery {
    height: 99.5vw;
    width: 107.185%;
    margin-left: -3.5925%;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .slick-slider .slick-list {
    overflow: visible;
  }
  .catalog-detail .catalog-detail-holder .image-gallery-block .image-gallery-block-holder .image-preview-gallery .image-preview-gallery-holder .image {
    width: 87px;
    height: 87px;
  }
  .catalog-detail .catalog-detail-holder .items-desc {
    width: 100%;
    padding: 0;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing {
    margin: 0 0 20px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .value {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #9C2E3B;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .pricing .pricing-holder .old-value {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 38px;
    color: #63636C;
    margin-left: 26px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .variants {
    margin: 0 0 20px;
  }
  .catalog-detail .catalog-detail-holder .items-desc .item-desc-holder .action .btn {
    min-width: auto;
    width: 100%;
    padding: 18px 10px;
  }
}
.catalog-item {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 280px;
}
.catalog-item .catalog-item-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-item .catalog-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.99;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .image .image-holder a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .desc {
  display: inline-block;
  width: 100%;
  padding: 16px 0 0;
  height: 103px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .desc .desc-holder .name {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 12px;
}
.catalog-item .catalog-item-holder .desc .desc-holder .name a {
  color: #000;
}
.catalog-item .catalog-item-holder .desc .desc-holder .name a:hover {
  color: #9C2E3B;
}
.catalog-item .catalog-item-holder .desc .desc-holder .pricing {
  display: flex;
  align-items: center;
  width: 100%;
}
.catalog-item .catalog-item-holder .desc .desc-holder .pricing .price-value {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.catalog-item .catalog-item-holder .desc .desc-holder .pricing .old-price-value {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #63636C;
  text-decoration: line-through;
  margin-left: 27px;
}
.catalog-item .catalog-item-holder .order {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .order .action {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .order .action .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #F2F3F5;
  border-color: #F2F3F5;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 100px;
  width: 100%;
  height: 58px;
  text-align: center;
  transition: all 300ms;
  padding: 0 6px 0 0;
}
.catalog-item .catalog-item-holder .order .action .btn:hover {
  color: white;
  background: #0B132B;
  border-color: #0B132B;
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .order .action .btn .icon {
  position: absolute;
  left: 4px;
  top: 2px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #0B132B;
  color: white;
  border-radius: 50%;
  text-align: center;
}
.catalog-item .catalog-item-holder .order .action .btn .icon svg {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .catalog-item .catalog-item-holder .image {
    height: 214px;
  }
  .catalog-item .catalog-item-holder .desc {
    height: 79px;
    padding: 13px 0 0;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .pricing .price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .pricing .old-price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .catalog-item .catalog-item-holder .order .action .btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    height: 44px;
    padding: 0 0 0 6px;
  }
  .catalog-item .catalog-item-holder .order .action .btn .icon {
    left: 1px;
    width: 38px;
    height: 38px;
    line-height: 38px;
  }
  .catalog-item .catalog-item-holder .order .action .btn .icon svg {
    width: 13px;
    height: 11px;
  }
}
.items-slider {
  display: inline-block;
  width: 100%;
  margin: 0 0 76px;
}
.items-slider .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 0 -1px;
}
.items-slider .items-slider-holder {
  display: inline-block;
  width: 100%;
}
@media (max-width: 1279px) {
  .items-slider {
    overflow: hidden;
    margin: 0 0 41px;
  }
  .items-slider .heading {
    text-align: center;
  }
  .items-slider .items-slider-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .items-slider .items-slider-holder .items-slider-container .catalog-item .catalog-item-holder {
    width: 281px;
  }
}
@media (max-width: 767px) {
  .items-slider {
    margin: 0 0 41px;
  }
  .items-slider .items-slider-holder .items-slider-container .catalog-item .catalog-item-holder {
    width: 215px;
  }
}
.catalog-list-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 108px;
}
.catalog-list-block .catalog-list-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-list-block .catalog-list-block-holder .heading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 54px;
}
.catalog-list-block .catalog-list-block-holder .heading .text {
  max-width: 50%;
}
.catalog-list-block .catalog-list-block-holder .heading .text > * {
  margin: 0;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories {
  max-width: 75%;
  margin: 3px -4px 0 0;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder {
  margin: 0 -8px;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link {
  float: left;
  margin: 0 8px;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link a {
  display: inline-block;
  border: 1px solid #808080;
  border-radius: 100px;
  min-width: 89px;
  text-align: center;
  padding: 13px 10px;
  background: transparent;
  color: #808080;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link a:hover {
  color: white;
  background: #000;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link a.current {
  color: white;
  background: #000;
  pointer-events: none;
  touch-action: none;
}
.catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link a.current:hover {
  color: white;
  background: #000;
}
.catalog-list-block .catalog-list-block-holder .controlls {
  display: inline-block;
  width: 100%;
  margin: 55px 0 0;
}

.catalog-list {
  display: inline-block;
  width: 100%;
}
.catalog-list .catalog-list-holder {
  display: inline-block;
  width: calc(100% + 28px);
  margin-left: -14px;
}
.catalog-list .catalog-list-holder .catalog-item {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0 0 50px;
}

@media (max-width: 1279px) {
  .catalog-list-block {
    margin: 0 0 60px;
  }
  .catalog-list-block .catalog-list-block-holder .heading {
    flex-direction: column;
    margin: 0 0 41px;
  }
  .catalog-list-block .catalog-list-block-holder .heading .text {
    width: 100%;
    max-width: none;
    text-align: center;
    margin: 0 0 33px;
  }
  .catalog-list-block .catalog-list-block-holder .heading .sub-categories {
    max-width: none;
    margin: 0;
  }
  .catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .catalog-list-block .catalog-list-block-holder .controlls {
    margin: 16px 0 0;
  }
  .catalog-list {
    box-sizing: border-box;
    padding: 0 1%;
  }
  .catalog-list .catalog-list-holder {
    width: calc(100% + 25px);
    margin-left: -12.5px;
    /*somehow catalog item in list are different from sldier one =( */
  }
  .catalog-list .catalog-list-holder .catalog-item {
    width: 33.3333%;
    padding: 0 12.5px;
    margin: 0 0 26px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .image {
    height: 30vw;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc {
    padding: 11px 0 0;
    height: 90px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .name {
    margin: 0 0 14px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing {
    margin: 0 0 0 4px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing .price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing .old-price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 33px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    height: 51px;
    padding: 0 0 0 4px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn .icon {
    top: 5px;
    left: 4px;
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn .icon svg {
    width: 15px;
    height: 13px;
  }
}
@media (max-width: 767px) {
  .catalog-list-block {
    margin: 0 0 50px;
  }
  .catalog-list-block .catalog-list-block-holder .heading {
    margin: 0 0 17px;
  }
  .catalog-list-block .catalog-list-block-holder .heading .text {
    margin: 0 0 27px;
  }
  .catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder {
    justify-content: flex-start;
    margin: 0;
    margin-left: -7.5px;
    width: calc(100% + 3px);
  }
  .catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link {
    width: 33.333%;
    box-sizing: border-box;
    padding: 0 7.5px;
    margin: 0 0 13px;
  }
  .catalog-list-block .catalog-list-block-holder .heading .sub-categories .sub-categories-holder .link a {
    min-width: auto;
    width: 100%;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .catalog-list-block .catalog-list-block-holder .controlls {
    margin: 11px 0 0;
  }
  .catalog-list .catalog-list-holder {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
  .catalog-list .catalog-list-holder .catalog-item {
    width: 50%;
    padding: 0 8px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .image {
    height: 44vw;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc {
    padding: 7px 0 0;
    height: 86px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 11px;
    height: 30px;
    overflow: hidden;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing {
    margin: 0;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing .price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .desc .desc-holder .pricing .old-price-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 33px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    height: 34px;
    padding: 0 0 0 26px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn .icon {
    top: 1px;
    left: 3px;
    width: 29px;
    height: 29px;
    line-height: 29px;
  }
  .catalog-list .catalog-list-holder .catalog-item .catalog-item-holder .order .action .btn .icon svg {
    width: 10px;
    height: 9px;
  }
}