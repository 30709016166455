@media (max-width: $screen-sm) {
    // sm-view
    .catalog-item {
        .catalog-item-holder { 
            .image { 
                height: 214px;
            }

            .desc { 
                height: 79px;
                padding: 13px 0 0;
                .desc-holder { 
                    .name {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                    }

                    .pricing { 
                        .price-value { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                        }

                        .old-price-value { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            .order { 
                .action { 
                    .btn {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        height: 44px;
                        padding: 0 0 0 6px;
                        .icon {
                            left: 1px;
                            width: 38px;
                            height: 38px;
                            line-height: 38px;
                            svg {
                                width: 13px;
                                height: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
