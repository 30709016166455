@media (max-width: $screen-lg) {
    // lg-md view
    .categories-list { 
        .categories-list-holder { 
            width: calc(100% +  15px);
            margin-left: -7.5px;
            box-sizing: border-box;
            padding: 0 10px;
            .category {
                padding: 0 7.5px;
                margin: 0 0 14px;
                .category-holder { 
                    .category-container { 
                        height: 70px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        .category-image { 
                            img {
                                max-height: 67px;
                                max-width: 81px;
                            }
                        }
                        .category-image.image-1 {
                            left: 13px;
                        }
                        .category-image.image-2 {
                            left: 13px;
                        }
                        .category-image.image-3 {
                            left: -1px;
                        }
                        .category-image.image-5 {
                            
                        }
                        .category-image.image-6 {
                            left: 7px;
                            img {
                                max-height: 60px;
                                max-width: 58px;
                            }
                        }
                        .category-image.image-8 {
                            
                        }

                        .category-desc { 
                            padding: 0 10px 0 86px;
                            .name {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
