.catalog-detail { 
    display: inline-block;
    width: 100%;
    margin: 39px 0 137px;
	.catalog-detail-holder { 
        display: inline-block;
        width: calc(100% + 41px);
        margin-left: -20.5px;
        .image-gallery-block { 
            float: left;
            width: 43.5%;
            box-sizing: border-box;
            padding: 0 20.5px;
            .image-gallery-block-holder { 
                display: inline-block;
                width: 100%;
                height: 25vw;
                margin: 3px 0 0;
                .image-gallery { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    .prev, .next { 
                        left: 12px;
                        transform: translateY(-22%);
                        svg { 

                        }
                    }

                    .next { 
                        left: auto;
                        right: 12px;
                        svg { 

                        }
                    }

                    .nav { 

                    }

                    .image-gallery-holder { 
                        height: 100%;
                        .image { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            a { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                border-radius: 4px;
                                overflow: hidden;
                                &:hover {
                                    img {
                                        transform: scale(1.1);
                                        transition: all 300ms;
                                    }
                                }
                                img { 
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: all 300ms;
                                }
                            }
                        }
                    }
                }

                .image-preview-gallery { 
                    
                    .image-preview-gallery-holder { 
                        width: calc(100% + 7px);
                        margin-left: -3.5px;
                        .image { 
                            width: 100%;
                            height: 10.5vw;
                            box-sizing: border-box;
                            padding: 0 3.5px;
                            a { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                border-radius: 2px;
                                overflow: hidden;
                                img { 
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
            }
        }

        .items-desc { 
            float: left;
            width: 56.5%;
            box-sizing: border-box;
            padding: 0 20.5px;
            .item-desc-holder { 
                display: inline-block;
                width: 100%;
                .name { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 13px;
                    .name-holder { 
                        display: inline-block;
                        width: 79%;
                        >* { 

                        }
                    }
                }

                .pricing { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 47px;
                    .pricing-holder { 
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .value { 
                            float: left;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 38px;
                            color: $brand-primary;
                        }

                        .old-value { 
                            float: left;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: $gray;
                            text-decoration: line-through;
                            margin-left: 26px;
                        }
                    }
                }

                .variants { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 46px;
                    .variant { 
                        display: inline-block;
                        width: 100%;
                        .variant-holder { 
                            display: inline-block;
                            width: 100%;
                            .heading { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 22px;
                                line-height: 26px;
                                margin: 0 0 24px;
                            }

                            .variant-selector { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 -10.5px;
                                .variant-type { 
                                    float: left;
                                    margin: 0 10.5px;
                                    a { 
                                        display: inline-block;
                                        width: 54px;
                                        height: 54px;
                                        border-radius: 50%;
                                        border: 1px solid #000000;
                                        &:hover {
                                            border-color: $brand-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .action { 
                    display: inline-block;
                    width: 100%;
                    .btn { 
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                        border-radius: 8px;
                        min-width: 268px;
                        text-align: center;
                        padding: 18px 20px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/detail";
@import "../../media/mobile/includes/catalog/detail";
