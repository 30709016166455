@media (max-width: $screen-lg) {
    // lg-md view
    .items-slider { 
        overflow: hidden;
        margin: 0 0 41px;
        .heading {
            text-align: center;
        }

        .items-slider-holder { 

            .slick-slider {
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            
                        }
                    }
                }
            }

            .items-slider-container { 
                .catalog-item {
                    .catalog-item-holder {
                        width: 281px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
