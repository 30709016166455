@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-list-block { 
        margin: 0 0 60px;
        .catalog-list-block-holder { 
            .heading { 
                flex-direction: column;
                margin: 0 0 41px;
                .text {
                    width: 100%;
                    max-width: none;
                    text-align: center;
                    margin: 0 0 33px;
                }

                .sub-categories { 
                    max-width: none;
                    margin: 0;
                    .sub-categories-holder { 
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                }
            }

            .controlls { 
                margin: 16px 0 0;
            }
        }
    }

    .catalog-list { 
        box-sizing: border-box;
        padding: 0 1%;
        .catalog-list-holder { 
            width: calc(100% + 25px);
            margin-left: -12.5px;

            /*somehow catalog item in list are different from sldier one =( */
            .catalog-item {
                width: 33.3333%;
                padding: 0 12.5px;
                margin: 0 0 26px;
                .catalog-item-holder {
                    .image {
                        height: 30vw;
                    }

                    .desc {
                        padding: 11px 0 0;
                        height: 90px;
                        .desc-holder {
                            .name {
                                margin: 0 0 14px;
                            }

                            .pricing { 
                                margin: 0 0 0 4px;
                                .price-value { 
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 21px;
                                }
        
                                .old-price-value { 
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 17px;
                                    margin-left: 33px;
                                }
                            }
                        }
                    }

                    .order { 
                        .action { 
                            .btn {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                height: 51px;
                                padding: 0 0 0 4px;
                                .icon {
                                    top: 5px;
                                    left: 4px;
                                    width: 44px;
                                    height: 44px;
                                    line-height: 44px;
                                    svg {
                                        width: 15px;
                                        height: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}

@media (max-width: $screen-md) {
    // md-view
}
