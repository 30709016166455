@media (max-width: $screen-sm) {
    // sm-view
    .items-slider { 
        margin: 0 0 41px;
        .heading {
            
        }

        .items-slider-holder { 

            .items-slider-container { 
                .catalog-item {
                    .catalog-item-holder {
                        width: 215px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
