.catalog-item { 
    display: inline-block;
    width: 100%;
	.catalog-item-holder { 
        display: inline-block;
        width: 100%;
		.image { 
            display: inline-block;
            width: 100%;
            height: 280px;
			.image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    opacity: 0.99;
                    border-radius: 6px;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
					img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
					}
				}
			}
		}

		.desc { 
            display: inline-block;
            width: 100%;
            padding: 16px 0 0;
            height: 103px;
            overflow: hidden;
			.desc-holder { 
                display: inline-block;
                width: 100%;
				.name { 
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    margin: 0 0 12px;
					a { 
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
					}
				}

				.pricing { 
                    display: flex;
                    align-items: center;
                    width: 100%;
					.price-value { 
                        font-style: normal;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 26px;
					}

					.old-price-value { 
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $gray;
                        text-decoration: line-through;
                        margin-left: 27px;
					}
				}

			}
		}

        .order { 
            display: inline-block;
            width: 100%;
            .action { 
                display: inline-block;
                width: 100%;
                .btn { 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    background: $gray-lighter;
                    border-color: $gray-lighter;
                    color: $gray-base;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    border-radius: 100px;
                    width: 100%;
                    height: 58px;
                    text-align: center;
                    transition: all 300ms;
                    padding: 0 6px 0 0;
                    &:hover {
                        color: white;
                        background: $brand-secondary;
                        border-color: $brand-secondary;
                        transition: all 300ms;
                    }
                    .icon { 
                        position: absolute;
                        left: 4px;
                        top: 2px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        background: $brand-secondary;
                        color: white;
                        border-radius: 50%;
                        text-align: center;
                        svg { 
                            vertical-align: middle;
                        }
                    }

                    .text { 

                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/catalog/item";
@import "../../media/mobile/includes/catalog/item";
