@media (max-width: $screen-sm) {
    // sm-view
    .catalog-detail { 
        overflow: hidden;
        margin: 47px 0 30px;
        .catalog-detail-holder { 
            width: 100%;
            margin-left: 0;
            padding: 0 1%;
            .name {
                width: 102%;
                margin: 0 0 28px;
                .name-holder {
                    display: inline-block;
                    width: 100%;
                    >* {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 36px;
                        margin: 0;
                    }
                }
            }
            .image-gallery-block { 
                width: 100%;
                padding: 0;
                margin: 0 0 15px;
                .image-gallery-block-holder {
                    .image-gallery { 
                        height: 99.5vw;
                        width: 107.185%;
                        margin-left: -3.5925%;
                    }

                    .image-preview-gallery { 

                        .slick-slider {
                            .slick-list {
                                overflow: visible;
                                .slick-track {
                                    .slick-slide {
                                        
                                    }
                                }
                            }
                        }

                        .image-preview-gallery-holder { 
                            .image { 
                                width: 87px;
                                height: 87px;
                            }
                        }
                    }
                }
            }

            .items-desc { 
                width: 100%;
                padding: 0;
                .item-desc-holder { 
                    .pricing { 
                        margin: 0 0 20px;
                        .pricing-holder { 
                            .value { 
                                font-style: normal;
                                font-weight: 500;
                                font-size: 32px;
                                line-height: 38px;
                                color: $brand-primary;
                            }
    
                            .old-value { 
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 38px;
                                color: $gray;
                                margin-left: 26px;
                            }
                        }
                    }

                    .variants { 
                        margin:  0 0 20px;
                    }

                    .action { 
                        .btn {
                            min-width: auto;
                            width: 100%;
                            padding: 18px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
