@media (max-width: $screen-sm) {
    // sm-view
    .catalog-list-block { 
        margin: 0 0 50px;
        .catalog-list-block-holder { 
            .heading { 
                margin: 0 0 17px;
                .text {
                    margin: 0 0 27px;
                }

                .sub-categories { 
                    .sub-categories-holder { 
                        justify-content: flex-start;
                        margin: 0;
                        margin-left: -7.5px;
                        width: calc(100% + 3px);
                        .link { 
                            width: 33.333%;
                            box-sizing: border-box;
                            padding: 0 7.5px;
                            margin: 0 0 13px;
                            a {
                                min-width: auto;
                                width: 100%;
                                box-sizing: border-box;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }
            .controlls {
                margin: 11px 0 0;
            }
        }
    }

    .catalog-list { 
        .catalog-list-holder { 
            width: calc(100% + 16px);
            margin-left: -8px;

            .catalog-item {
                width: 50%;
                padding: 0 8px;
                .catalog-item-holder {
                    .image {
                        height: 44vw;
                    }

                    .desc {
                        padding: 7px 0 0;
                        height: 86px;
                        .desc-holder {
                            .name {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                margin: 0 0 11px;
                                height: 30px;
                                overflow: hidden;
                            }

                            .pricing { 
                                margin: 0;
                                .price-value { 
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                }
        
                                .old-price-value { 
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 17px;
                                    margin-left: 33px;
                                }
                            }
                        }
                    }

                    .order { 
                        .action { 
                            .btn {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 12px;
                                height: 34px;
                                padding: 0 0 0 26px;
                                .icon {
                                    top: 1px;
                                    left: 3px;
                                    width: 29px;
                                    height: 29px;
                                    line-height: 29px;
                                    svg {
                                        width: 10px;
                                        height: 9px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
