.catalog-list-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 108px;
	.catalog-list-block-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: flex;
            justify-content: space-between;
            margin: 0 0 54px;
            .text { 
                max-width: 50%;
                >* { 
                    margin: 0;
                }
            }

            .sub-categories { 
                max-width: 75%;
                margin: 3px -4px 0 0;
                .sub-categories-holder { 
                    margin: 0 -8px;
                    .link { 
                        float: left;
                        margin: 0 8px;
                        a {
                            display: inline-block;
                            border: 1px solid #808080;
                            border-radius: 100px;
                            min-width: 89px;
                            text-align: center;
                            padding: 13px 10px;
                            background: transparent;
                            color: #808080;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            &:hover {
                                color: white;
                                background: $gray-base;
                            }
                            &.current { 
                                color: white;
                                background: $gray-base;
                                pointer-events: none;
                                touch-action: none;
                                &:hover {
                                    color: white;
                                    background: $gray-base;
                                }
                            }
                        }

                    }
                }
            }
        }

        .controlls { 
            display: inline-block;
            width: 100%;
            margin: 55px 0 0;
        }
    }
}

.catalog-list { 
    display: inline-block;
    width: 100%;
    .catalog-list-holder { 
        display: inline-block;
        width: calc(100% + 28px);
        margin-left: -14px;
        .catalog-item {
            width: 25%;
            float: left;
            box-sizing: border-box;
            padding: 0 14px;
            margin: 0 0 50px;
        }
    }
}

@import "../../media/tablet/includes/catalog/list";
@import "../../media/mobile/includes/catalog/list";
