.items-slider { 
    display: inline-block;
    width: 100%;
    margin: 0 0 76px;
    .heading {
        display: inline-block;
        width: 100%;
        margin: 0 0 0 -1px;
    }

	.items-slider-holder { 
        display: inline-block;
        width: 100%;
		.prev, .next { 

			svg { 

			}
		}

		.next { 

			svg { 

			}
		}

		.nav { 

		}

		.items-slider-container { 

		}
	}
}

@import "../../media/tablet/includes/catalog/items_slider";
@import "../../media/mobile/includes/catalog/items_slider";
